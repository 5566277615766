import * as React from 'react';

import type { ActiveLocale } from '@dnc/baseline/utils/localization';

// This defaults to Spanish mostly so we’d notice in dev if we fail to set it
// up.
export const LocaleContext = React.createContext('es' as ActiveLocale);

export function useLocale(): ActiveLocale {
  return React.useContext(LocaleContext);
}
