/**
 * This file holds IWV's definitions of languages and locales, as well as various helper methods to support
 * how locales are used in IWV.
 *
 * As a baseline:
 * - 'language' refers to the English name of a given language
 * - 'locale' refers to the ISO code for a given language
 *
 * How locales are defined in IWV:
 * - the 'default' locales configured as English (en), Spanish (es)
 * - the 'custom' locales configured as any/all locales a US State can opt into supporting
 * - and to unify the two, 'active' locales
 *
 * Why differentiate between default and custom locales?
 * In IWV's source code:
 * - All localized strings are expected to be translated across all locales (default AND custom)
 * HOWEVER, in Contentful:
 * - At _minimum_, English and Spanish content is expected to exist, because IWV supports
 *   English and Spanish content across the board nationally
 * - Any/all other localized content is not necessarily guaranteed to exist,
 *   because each US State + DC has to "opt in" to support a given locale.
 *
 * It is not unreasonable to expect that the definition of "default" locales
 * could expand beyond English and Spanish. However, given the unique population
 * makeup of each US State + DC, even if the definition of "default" locales expands,
 * there will still be a need for customizable/opt-in locale support on a state-by-state basis.
 *
 * Given all of the above, both IWV and Contentful need to have definitions for _every_ locale
 * supported across all states, no matter how many US States support each locale.
 *
 * The `languageSupport` field, configured on the Contentful Jurisdiction Config object, describes which languages,
 * a US State/DC is opting into support. The `languageSupport` field is configured as an array of language names,
 * for ease of human legibility.
 *
 * In order to support the expected locales for a given US State/DC, IWV maps the `languageSupport` field values
 * to each _language_'s corresponding _locale_. IWV is oriented to operate locale-first, instead of language-name-first,
 * for ease of code execution.
 */
import { type Document } from '@contentful/rich-text-types';

import type { Option } from './option';

export const DefaultLanguageToLocale = {
  English: 'en',
  Spanish: 'es',
} as const;
export type DefaultLanguage = keyof typeof DefaultLanguageToLocale;
export type DefaultLocale = (typeof DefaultLanguageToLocale)[DefaultLanguage];

/**
 * CustomLanguageToLocale represents the Contentful
 * configuration of language names to ISO locale codes.
 *
 * NOTE: When updating locales and the `languageSupport`
 * field in Contentful, {@link CustomLanguageToLocale}
 * needs to be updated as well.
 */
export const CustomLanguageToLocale = {
  Arabic: 'ar',
  Korean: 'ko',
  Shoshone: 'shh',
  'Simplified Chinese': 'zh-Hans',
  Tagalog: 'tl',
  Vietnamese: 'vi',
} as const;
export type CustomLanguage = keyof typeof CustomLanguageToLocale;
export type CustomLocale = (typeof CustomLanguageToLocale)[CustomLanguage];
export const ActiveLanguageToLocale = {
  ...DefaultLanguageToLocale,
  ...CustomLanguageToLocale,
};

/**
 * Invert mapping of ActiveLanguageToLocale
 * so language name values can be accessed with locale keys
 * e.g. LocaleToLanguage["es"] = "Spanish"
 */
export const ActiveLocaleToLanguage = Object.fromEntries(
  Object.entries(ActiveLanguageToLocale).map(([lang, locale]) => [locale, lang])
);

export type ActiveLanguage = DefaultLanguage | CustomLanguage;
export type ActiveLocale = DefaultLocale | CustomLocale;

export const DEFAULT_LOCALES: ActiveLocale[] = [
  ActiveLanguageToLocale['English'],
  ActiveLanguageToLocale['Spanish'],
];

/**
 * Expected use cases for isDefaultLocale:
 * - temporary validation for toggling a locale before
 * language selector exists
 */
export function isDefaultLocale(locale: string): locale is DefaultLocale {
  const defaultLocales = Object.values(DefaultLanguageToLocale);
  return defaultLocales.includes(locale as DefaultLocale);
}

/**
 * RIGHT_TO_LEFT_LOCALES defines locales which are
 * read right to left, instead of left to right.
 * When {@link CustomLanguageToLocale} support is
 * expanded, engineers need to be sensitive to update
 * RIGHT_TO_LEFT_LOCALES as needed/when appropriate.
 */
export const RIGHT_TO_LEFT_LOCALES: ActiveLocale[] = [
  ActiveLanguageToLocale['Arabic'],
];

export function localeReadsRightToLeft(locale: ActiveLocale) {
  return RIGHT_TO_LEFT_LOCALES.includes(locale);
}

/**
 * Expected use cases for isActiveLocale:
 * - validating whether IWV has a definition for a locale
 * that comes from query params
 * - support to validate whether IWV has a definition for
 * a user's preferred browser language (represented as a locale)
 */
export function isActiveLocale(
  locale: string | undefined
): locale is ActiveLocale {
  const activeLocales = Object.values(ActiveLanguageToLocale);
  return activeLocales.includes(locale as ActiveLocale);
}

/**
 * Expected use cases for isActiveLanguage:
 * - validating whether IWV has a definition for a language
 * that comes from Contentful
 * This will specifically protect production from attempting
 * to handle a newly-added-in-Contentful language, before
 * IWV has been updated to support said language
 * and its corresponding locale.
 */
export function isActiveLanguage(lang: string): lang is ActiveLanguage {
  const activeLanguages = Object.keys(ActiveLanguageToLocale).map(
    (langKey) => langKey as ActiveLanguage
  );
  return activeLanguages.includes(lang as ActiveLanguage);
}

// LANGUAGE SELECTOR LABELS
/**
 * Language selector labels used to articulate
 * '<LANGUAGE NAME>' in the target language.
 */
export const LocaleToLanguageName: {
  [key in ActiveLocale]: string;
} = {
  ar: 'العربية',
  en: 'English',
  es: 'Español',
  ko: '한국어',
  shh: 'Shoshone',
  tl: 'Tagalog',
  vi: 'Tiếng Việt',
  'zh-Hans': '中文(简体)',
};

// STRING TYPES

/**
 * Localized string content that may be null,
 * in the {@link DefaultLocale}s: 'en' and 'es'.
 * VepApi DefaultLocale content is safely nullable
 * where the content may not exist
 * ex: custom state voter hotline
 */
type VepApiDefaultLocalizedNullableString = {
  [key in DefaultLocale]: string | null;
};

/**
 * Custom locales are optional for both {@link LocalizedString} and
 * VepApiCustomLocalizedNullableString.
 *
 * The localized content is a string or null for
 * VepApiCustomLocalizedNullableString.
 */
export type VepApiCustomLocalizedNullableString = {
  [key in CustomLocale]?: string | null;
};

/**
 * An optional Contentful string field. There is no guarantee that any of the
 * locales are provided.
 *
 * Use {@link hasEnglishLocale} and {@link hasDefaultLocales} to convert this to
 * either a {@link MinimumEnLocalizedString} or a {@link ActiveLocalizedString}.
 */
export type LocalizedString = { [k in ActiveLocale]?: string };

/**
 * String that was marked as required in Contentful. Guaranteed to have `en` and
 * `es` values by the Contentful Locale configuration (though not the Contentful
 * types).
 */
export type ActiveLocalizedString = { [k in DefaultLocale]: string } & {
  [k in CustomLocale]?: string;
};

/**
 * A localized string that has at minimum an "en" entry.
 *
 * We often have to determine this at runtime (see {@link hasEnglishLocale})
 * because if a field is not marked as required in Contentful then nothing will
 * enforce that an English version was written.
 *
 * This type is useful for cases where there’s non-displayed data, like a URL,
 * that _could_ be localized but may just be the same in each language.
 */
export type MinimumEnLocalizedString = { en: string } & {
  [k in ActiveLocale]?: string;
};

/**
 * A Contentful rich text field. There is no guarantee that any of the locales
 * are provided.
 *
 * Use {@link hasDefaultLocales} to convert this to a
 * {@link ActiveLocalizedRichTextObj}.
 */
export type LocalizedRichTextObj = { [k in ActiveLocale]?: Document };

/**
 * A rich text object that we know has both `en` and `es` locales
 * {@link DefaultLocale} defined. May also have {@link CustomLocale} values.
 *
 * Any required Contentful fields will have these locales defined (as ensured by
 * our Contentful Locale configuration) but optional fields may not.
 *
 * @see hasDefaultLocales
 */
export type ActiveLocalizedRichTextObj = { [k in DefaultLocale]: Document } & {
  [k in CustomLocale]?: Document;
};

/**
 * True if an object has an `en` locale.
 *
 * Mostly you should use {@link hasDefaultLocales}, but this can come up for
 * _e.g._ URLs where it’s ok we didn’t specify the Spanish version and want to
 * fall back to EN in all cases.
 *
 * Often necessary to process optional fields, since we don’t have any static
 * guarantees about which locales were defined.
 */
export function hasEnglishLocale<T>(
  val:
    | {
        [k in ActiveLocale]?: T;
      }
    | undefined
): val is { en: T } & {
  [k in CustomLocale | Exclude<DefaultLocale, 'en'>]?: T;
} {
  return !!val && typeof val.en !== 'undefined';
}

/**
 * True if an object has both `en` and `es` values.
 *
 * Often necessary to process optional fields, since we don’t have any static
 * guarantees about which locales were defined.
 */
export function hasDefaultLocales<T>(
  val:
    | {
        [k in ActiveLocale]?: T;
      }
    | undefined
): val is { [k in DefaultLocale]: T } & { [k in CustomLocale]?: T } {
  return (
    !!val && typeof val.en !== 'undefined' && typeof val.es !== 'undefined'
  );
}

/**
 * VepApi content is safely nullable across locales
 * Unifies expected/nullable default language content (en/es)
 * with variable and nullable locale content
 * (see {@link CustomLocale})
 */
export type VepApiActiveLocalizedNullableString =
  VepApiDefaultLocalizedNullableString & VepApiCustomLocalizedNullableString;

// BEGIN HELPER METHODS FOR LOCALIZED CONTENT

/**
 * Reusable helper function that retrieves either:
 * - the localized content for a requested locale, if the content exists,
 * or
 * - the localized content in English/Spanish, based on the hostname
 *
 * USECASE: While localized content in IWV's source code should be localized across
 * all locales, Contentful objects are not required to have content for all
 * locales -- only the locales a US State supports translations for, described in
 * {@link JurisdictionLocaleSupport}.
 * @param locale An {@link ActiveLocale}
 * @param content Either an {@link ActiveLocalizedString} or {@link ActiveLocalizedUrl}, which
 * may or may not have content available for the requested locale.
 * @param fallbackLocale 'en' (English) or 'es' (Spanish), as determined by hostname.
 */
export function getLocalizedContent(
  locale: ActiveLocale,
  content: MinimumEnLocalizedString | VepApiActiveLocalizedNullableString,
  fallbackLocale: DefaultLocale = defaultHostLocale()
): string {
  let fallbackContent = content[fallbackLocale] ?? '';
  // if the fallback locale is Spanish, but there is no content in Spanish, fallback to English content
  if (fallbackLocale === 'es' && !content['es'] && content['en']) {
    fallbackContent = content['en'];
  }
  return content[locale] ?? fallbackContent;
}

/**
 * Reusable helper function (specific to RichText from Contentful) that retrieves either:
 * - the localized rich text content for a requested locale, if the content exists,
 * or
 * - the localized rich text content in English/Spanish, based on the hostname
 *
 * USECASE: Contentful rich text content may not exist for all locales in all jurisdictions.
 * {@link JurisdictionLocaleSupport} describes the locales a jurisdiction supports.
 * @param locale An {@link ActiveLocale}
 * @param richTextObject {@link ActiveLocalizedRichText} object which may/may not have content available for the requested locale.
 * @param fallbackLocale 'en' (English) or 'es' (Spanish), as determined by hostname.
 */
export function getLocalizedRichText(
  locale: ActiveLocale,
  richTextObject: ActiveLocalizedRichTextObj,
  fallbackLocale: DefaultLocale = defaultHostLocale()
): Document {
  return richTextObject[locale] ?? richTextObject[fallbackLocale];
}

// END HELPER METHODS FOR LOCALIZED CONTENT

// BEGIN URL METHODS FOR HANDLING LOCALIZATION
/**
 * Returns the locale based on the host name. So “voyavotar.com” would return
 * {@link DefaultLanguageToLocale[Spanish]} ('es') while “iwillvote.com” (and any other host, it’s the
 * default) returns {@link DefaultLanguageToLocale[English]} ('en').
 */
export function defaultHostLocale(
  location: Option<Pick<Location, 'host'>> = typeof window !== 'undefined'
    ? window.location
    : undefined
): DefaultLocale {
  const esPattern = RegExp(/voyavotar/);

  if (location && esPattern.exec(location.host)) {
    return DefaultLanguageToLocale['Spanish'];
  } else {
    return DefaultLanguageToLocale['English'];
  }
}

/**
 * Returns a locale if specified by user browser preference.
 * Handles a user's preference with awareness of ISO codes
 * with country codes (which may not be _exact_ matches to an
 * {@link ActiveLocale}).
 */
export function defaultBrowserLocale(
  userPreference: Pick<Navigator, 'language'>
): Option<ActiveLocale> {
  // handle ISO locales with country code
  const preferredLocale = userPreference.language.split('-')[0]!;
  if (isActiveLocale(preferredLocale)) {
    return preferredLocale;
  } else {
    return undefined;
  }
}

// END URL METHODS FOR HANDLING LOCALIZATION
