import React from 'react';
import { IntlProvider, type MessageFormatElement } from 'react-intl';

import type { ActiveLocale } from '@dnc/baseline/utils/localization';

/* eslint-disable import/extensions */
// In dev these refer to the messages directory, but for production builds the
// Vite config re-writes this import alias to go to the compile-messages
// directory (so we don’t have to ship the i18n parser in prod).
import arMessages from '@dnc/messages/ar.json';
import enMessages from '@dnc/messages/en.json';
import esMessages from '@dnc/messages/es.json';
import koMessages from '@dnc/messages/ko.json';
import tlMessages from '@dnc/messages/tl.json';
import viMessages from '@dnc/messages/vi.json';
import zhHansMessages from '@dnc/messages/zh-Hans.json';
/* eslint-enable import/extensions */

import { LocaleContext } from '@dnc/shared-components/LocaleContext';

// We specifically don’t use @dnc/messages because we don’t want this path to be
// re-written between dev and prod.
//
// eslint-disable-next-line import/extensions
import { compile } from '../../messages/formats.mjs';

export const DEFAULT_RICH_TEXT_ELEMENTS = {
  ltr: (chunks: React.ReactNode[]) => <span dir="ltr">{chunks}</span>,
  rtl: (chunks: React.ReactNode[]) => <span dir="rtl">{chunks}</span>,
};

/**
 * Sets language used for defaultMessage (fallback) in
 * FormattedMessage component from react-intl
 */
export const DEFAULT_LOCALE = 'en';

export const locales: {
  [lang in ActiveLocale]: {
    messages:
      | { [msgId: string]: string }
      | { [msgId: string]: MessageFormatElement[] };
  };
} = {
  ar: {
    messages: prepareMessages(arMessages),
  },
  en: {
    messages: prepareMessages(enMessages),
  },
  es: {
    messages: prepareMessages(esMessages),
  },
  ko: {
    messages: prepareMessages(koMessages),
  },
  shh: { messages: {} },
  tl: { messages: prepareMessages(tlMessages) },
  vi: {
    messages: prepareMessages(viMessages),
  },
  'zh-Hans': { messages: prepareMessages(zhHansMessages) },
};

/**
 * Converts the JSON files from the `messages` directory (which are modelled after
 * the extracted en.json file) into the `{id: "message"}` format for react-intl.
 *
 * Also handles the case where we’re in production and the webpack config has
 * pointed @dnc/messages to the compiled version of the strings.
 */
function prepareMessages(msgs: { [id: string]: any }) {
  if (
    // See vite.config.ts or webpack.prod.config.cjs
    typeof __USE_PRECOMPILED_MESSAGES__ !== 'undefined' &&
    __USE_PRECOMPILED_MESSAGES__
  ) {
    return msgs as { [msgId: string]: MessageFormatElement[] };
  }

  return compile(msgs);
}

/**
 * Hides the "you should precompile" warning in dev when we’re… not
 * precompiling. On purpose.
 */
function warnWithoutPrecompileMessages(str: string) {
  if (
    __USE_PRECOMPILED_MESSAGES__ ||
    !str.includes('pre-compile your messages for performance')
  ) {
    console.warn(str);
  }
}

/**
 * Our {@link IntlProvider} implementation, which includes our messages and
 * other configuration.
 *
 * Also wraps with {@link LocaleContext}’s provider.
 */
export const IwvIntlProvider: React.FunctionComponent<{
  locale: ActiveLocale;
  children: React.ReactNode;
}> = ({ children, locale }) => {
  return (
    <IntlProvider
      locale={locale}
      defaultLocale={DEFAULT_LOCALE}
      messages={locales[locale].messages}
      defaultRichTextElements={DEFAULT_RICH_TEXT_ELEMENTS}
      onWarn={warnWithoutPrecompileMessages}
    >
      <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>
    </IntlProvider>
  );
};
